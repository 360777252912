<template >
  <div
	  class="reports"
  >
 <colel-card

	 :title="
           $t('reports.title')
      "
	 :isLoading="isLoading"
	 :icon="'mdi-chart-box'"
 >
      <template v-slot:extra-header>

        <v-tooltip
		   bottom
	   >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
			  :disabled="(!reportType || (reportType.value === 'oneColel'
			  && colel_id === null))"
			  @click="download"
			  color="primary"
			  elevation="2"
			  v-bind="attrs"
			  v-on="on"
		  >


<!--			{{ // $t("reports.download") }}-->
	  <v-icon >
				 right
        mdi-download
      </v-icon >
            </v-btn >
          </template >
          <span >{{ $t("reports.download") }}</span >
        </v-tooltip >
      </template >
      <template v-slot:content>
    <v-form v-model="valid">
    <v-container >
      <v-row >

		<v-col
			cols="12"
			xs="12"
			sm="8"
			md="6"
		>
           <Dropdown
			 v-if="isAdmin"
			 style="width:100%"
			 :showClear="true"
			 :filter="true"
			 option-label="displayName"
			 v-model="reportType"
			 :options="reportsTypes"
			 :placeholder="$t(`reports.type`)"
		 />
        </v-col >
		   <v-col
			   v-if="showColelDropdown && isAdmin"
			   cols="12"
			   xs="12"
			   sm="8"
			   md="6"
		   >
           <Dropdown
               style="width:100%"
               :showClear="true"
               :filter="true"
               v-model="colel_id"
               :options="['הכל', ...colelIds]"
               :placeholder="$t(`reports.colel`)"
           />

        </v-col >
		<v-col
			cols="12"
			xs="12"
			sm="8"
			md="6"
		>
		  <Calendar
			  style="width:100%"
			  v-model="date"
			  view="month"
			  dateFormat="mm/yy"
			  :yearNavigator="true"
		  ></Calendar >
        </v-col >
		 <v-col
			 cols="12"
			 xs="12"
			 sm="6"
			 md="4"
		 >

			        </v-col >



      </v-row >
    </v-container >
  </v-form >
		      </template >

	 </colel-card >
  </div >
</template >

<script>

import {mapGetters, mapState} from "vuex";
import {getReport} from "@/views/reports/components/Reports";
import constants from "@/constants";

const reportsTypes = [
  {displayName: 'דוח כולל', value: 'oneColel'},
  {displayName: 'פרטי אברכים', value: 'persons'},
  {displayName: 'דוח איזי', value: 'attendance'},
  {displayName: 'דוח העברה', value: 'transport'}
]

export default {
  name: "reports",
  components: {},
  methods: {
    async download() {
      try {
        this.isLoading = true
        const files = await getReport(this.reportType, this.date, this.colel_id, this.colelIds)
        if (files === 0) {
          this.$toast.add({
            severity: 'error',
            summary: this.$t('errors.fileNotFound'),
            life: constants.LIFE_TOAST
          })
        } else if (files < this.colelIds.length) {
          this.$toast.add({
            severity: 'error',
            summary: this.$t('errors.fileNotFound'),
            life: constants.LIFE_TOAST
          })
        } else {
          this.$toast.add({
            severity: 'success',
            summary: this.$t('success.gettingReport',
                {typerepot: this.reportType.displayName}),
            life: constants.LIFE_TOAST
          });
        }

      } catch (e) {
        if (e.response.data.error === 'Report was not complete for this month'){
          this.$toast.add({
                            severity: 'error',
                            summary: this.$t('errors.gettingReportNotComplete'),
                            life: constants.LIFE_TOAST

                          })
        } else {
          this.$toast.add({
                            severity: 'error',
                            summary: this.$t('errors.gettingReport',
                                             {typerepot: this.reportType.displayName}),
                            life: constants.LIFE_TOAST

                          })
        }
      }
      this.isLoading = false


    },
  },
  mounted() {
    if (!this.isAdmin) {
      this.colel_id = this.colel?.colel?.id
      this.reportType = {displayName: 'דוח כולל', value: 'oneColel'}
    }
  },
  data() {
    return {
      isLoading: false,
      colel_id: null,
      date: new Date(),
      reportType: null
    };
  },
  computed: {
    showColelDropdown() {
      return this.reportType?.value === 'oneColel'
    },
    reportsTypes() {
      return reportsTypes
    },
    ...mapState(['colelIds', 'colel']),
    ...mapGetters(["isAdmin"])
  }
};
</script >

<style scoped>
>>> .p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
    /*padding-right: 0 !important;*/
    margin-right: 0 !important;
}

</style >
