import axios from "axios";
import {dateToMonthAndYear} from "@/utils/dateFormat";
import JSZip from "jszip";
import {downloadXlsx} from "@/utils/responseToFile";

const reportsTypes = [
  {displayName: 'דוח כולל', value: 'oneColel'},
  {displayName: 'פרטי אברכים', value: 'persons'},
  {displayName: 'דוח איזי', value: 'ez_report'},
  {displayName: 'דוח העברה', value: 'transport'}
]


export async function getReport(type, date, colelId, colelsList) {
  const dateReq = dateToMonthAndYear(date)
  const zip = new JSZip();
  let res;
  let url;
  try {
    if (type.value === 'oneColel') {
      if (colelId !== 'הכל') {
        res = await axios({url: `/files/oneColel/${dateReq}/${colelId}`, responseType: 'blob'})
      } else {
        await Promise.allSettled(
            colelsList.map(id => {
              return axios({url: `/files/oneColel/${dateReq}/${id}`, responseType: 'blob'})
                  .then(result => {
                    zip.file(`${id} - ${date.getMonth() + 1}.${date.getFullYear()}.xlsx`, result.data, {base64: true});
                  })
            }))
        if (Object.keys(zip.files).length > 0) {
          zip.generateAsync({type: "blob"}).then(function (content) {
            downloadXlsx(content, "דוח כולל.rar");
          });
        }
        return Object.keys(zip.files).length;
      }
    } else {
      if (type.value === 'attendance') {
        url = `/files/ez_report/${dateReq}`;
        res = await axios({url, responseType: 'blob'});
      } else {
        url = colelId ? `/files/${type.value}/${dateReq}/${colelId}` :
            `/files/${type.value}/${dateReq}`;
        res = await axios({url, responseType: 'blob'});
      }
    }

  } catch (e) {
    res = axios({url, responseType: 'application/json'})
  }

  let fileName
  if (type.value === 'oneColel') {
    fileName = `${colelId}-${dateReq}`;
  } else {
    fileName = `${type.displayName}-${dateReq}`;
  }
  downloadXlsx(res.data, fileName)
}