<template >
  <div class="Report">
	 <div class="p-grid p-jc-center">
	 	<div class=" p-col-12 p-md-9 p-lg-6  p-p-5 ">
		    <Reports></Reports >
	 	</div >
	 </div >
  </div >
</template >

<script >
import Reports from "@/views/reports/components/reports.vue";
import {mapState, mapActions} from "vuex";

export default {
  name: "Report",
  components: {
    Reports
  },
  mounted() {
  },
  data() {
    return {
      pickLevel: '0'
    };
  },
  methods: {
    ...mapActions([])
  },
  computed: {
    ...mapState(["colel"])
  }
};
</script >

