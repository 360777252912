
export const downloadXlsx = (fileData,  fileName) => {
  const blob = new Blob([fileData], {
    type:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  });
  download(blob, fileName)

}
export const download = (blob, fileName) => {
  if ("download" in document.createElement("a")) {
    const elink = document.createElement("a");
    elink.download = fileName;
    elink.style.display = "none";
    elink.href = URL.createObjectURL(blob);
    document.body.appendChild(elink);
    elink.click();
    URL.revokeObjectURL(elink.href) // release the URL object
    document.body.removeChild(elink);
  }
}
